<script>
import magnifier from '@/assets/icons/magnifier.svg';

export default {
    name: "NoOrders",   
    data() {
      return {
        magnifier,
      }
  }, 
}
</script>

<template>
  <div class="no-orders">
    <div class="no-orders__container">
      <img :src="magnifier" alt="magnifier" class="no-orders__icon" />
      <div class="no-orders__title">{{ $t("noOrders") }}</div>
    </div>
  </div>
</template>

<style lang="scss">
@import "~styles/components/no-orders.scss";
</style>