<script>
import empty from '@/assets/icons/empty.svg';

export default {
    data() {
        return {
            empty,
        }
    }
}
</script>

<template>
  <div class="no-campaigns">
    <img :src="empty" alt="empty" class="no-campaigns__icon" />
    <div class="no-campaigns__title">{{ $t("noCampaigns") }}</div>
    <div class="no-campaigns__subtitle">{{ $t("contactAdmin") }}</div>
  </div>
</template>

<style lang="scss">
@import "~styles/views/_no-campaigns.scss";
</style>

