<script>
import LogoutIcon from '@/assets/icons/LogoutIcon.vue';
import { useRoute } from 'vue-router';

export default {
    name: 'PageHeader',
    setup() {
      const route = useRoute();
      
      return { route }
    },
    components: {
      LogoutIcon
    },
    methods: {
      showLogoutModal() {
        this.$emit('show-logout-modal');
      },
    } 
}
</script>

<template>
  <div class="header">
    <div />
    <div v-if="route.name === 'home' || route.name === 'no-campaigns'">
      {{ $t("orders") }}
    </div>
    <div v-else>
      {{ $t("pastOrders") }}
    </div>
    <div class="header__logout" @click="showLogoutModal">
      <LogoutIcon class="header__icon" />
      {{ $t("logout") }}
    </div>
  </div>
</template>

<style lang="scss">
@import "~styles/layouts/header.scss";
</style>