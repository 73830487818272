<script>
import { store } from '../store/index';
import { VueScreenSizeMixin } from 'vue-screen-size';
import ViewButton from '@/components/ViewButton.vue';
import Button from '@/components/ButtonComponent.vue';
import router from '@/router';
import { useRoute } from 'vue-router';
import { ref, watch } from 'vue';
import ButtonFilter from '@/components/ButtonFilter.vue';
import history from '@/assets/icons/history.svg';
import orders from '@/assets/icons/orders.svg';

export default {
  name: 'PageBody',
  components: { ViewButton, Button, ButtonFilter },
  mixins: [VueScreenSizeMixin],
  setup() {
    const route = useRoute();
    const hasToken = ref(!!localStorage.getItem('AUTH_TOKEN'));

    const setAuthToken = (value) => {
      if (value) {
        localStorage.setItem('AUTH_TOKEN', value);
        hasToken.value = true;
      }
    };

    watch(() => route.query.data, value => setAuthToken(value));

    return { route, hasToken }
  },
  data() {
    return {
      store,
      history,
      orders,
      enableChangeView: false,
    }
  },
  mounted() {
    if (this.$vssHeight >= 800) {
      this.enableChangeView = true;
    }
  },
  methods: {
    goToRoute(){
      if(this.route.name === 'home'){
        router.push({ path: `/${this.route.params.id}/past-orders` });
      }else {
        router.push({ path: `/${this.route.params.id}/home` });
      } 
    },
    selectedCampaign(id) {
      if(this.route.name === 'home') {
        router.replace({ name: 'home', params: { id } });
      }else {
        router.replace({ name: 'past-orders', params: { id } });
      }
    },
  }
}
</script>

<template>
  <div class="body">
    <div
      v-if="route.name !== 'no-campaigns'"
      class="body__header"
      :class="{
        'body__header-full': enableChangeView,
      }"
    >
      <ViewButton v-if="enableChangeView" />
      <div class="body__button-group">
        <ButtonFilter
          @update:campaign="selectedCampaign"
          :campaign-id="route.params.id"
          v-if="hasToken"
        />
        <Button color="muted" size="lg" @click="goToRoute">
          <span v-if="route.name === 'home'" class="body__button">
            <img :src="history" alt="history" class="body__icon" />
            {{ $t("pastOrders") }}</span
          >
          <span v-else class="body__button">
            <img :src="orders" alt="orders" class="body__icon" />
            {{ $t("orders") }}</span
          >
        </Button>
      </div>
    </div>
    <slot />
  </div>
</template>

<style lang="scss">
@import "~styles/layouts/body.scss";
</style>