<script>
import { store } from '../store/index';
import columns from '@/assets/icons/columns.svg';
import square from '@/assets/icons/square.svg';

export default {
    name: "ViewButton",
    data() {
      return {
        store,
        columns,
        square
      }
   },  
}
</script>

<template>
  <div class="button-container">
    <div
      class="button"
      :class="{ selected: store.view === 'full' }"
      @click="store.view === 'small' && store.changeView()"
    >
      <img :src="columns" alt="columns" class="button__icon" />
    </div>
    <div
      class="button"
      :class="{ selected: store.view === 'small' }"
      @click="store.view === 'full' && store.changeView()"
    >
      <img :src="square" alt="square" class="button__icon" />
    </div>
  </div>
</template>

<style lang="scss">
@import "~styles/components/view-button.scss";
</style>