<script>
export default {
  name: 'CardProduct',
  props: {      
    product: {
      type: Object,
      required: true,
    }
  },
}
</script>

<template>
  <div class="card__product">
    <div class="product__name">
      <div class="product__quantity">{{ product.quantity }}</div>
      {{ product.name }}
    </div>
    <div
      v-for="option in product.optionsGroup"
      :key="option.id"
      class="product__options"
    >
      <div class="product__option">
        <div class="product__option-title">{{ option.name }}:</div>
        <div v-for="(item, index) in option.options" :key="item.name">
          <span v-if="index + 1 !== option.options.length">{{
            `${item.name}, `
          }}</span>
          <span v-else>{{ item.name }}</span>
        </div>
      </div>
    </div>
    <div v-if="product.comment" class="product__comments">
      <div class="product__comments-badge">{{ $t("comments") }}</div>
      <div class="product__comments-text">
        {{ product.comment }}
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "~styles/components/card.scss";
</style>