<script>
import CardProduct from './CardProduct.vue';

export default {
  name: 'CardInfo',
  components: { CardProduct },
  props: {
    orderNumber: {
      type: Number, 
      default: 0,
    },
    cart: {
      type: Array,
      required: true,
    },
  },
  computed: {
    orderCart() {
      return this.cart.car ?? [];
    },
    addressCart() {
      return this.cart?.location?.address || ''
    }
  },
}
</script>

<template>
  <div class="card__info">
    <div class="card__order">{{ `${$t("order")} #${orderNumber}` }}</div>
    <div v-if="addressCart" class="card__address">{{ this.$t('address') }}: {{ addressCart }}</div>
    <CardProduct
      v-for="(product, index) in orderCart"
      :key="index"
      :product="product"
    />
  </div>
</template>

<style lang="scss">
@import "~styles/components/card.scss";
</style>


