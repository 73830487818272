<script>
import { ref } from "vue";
import { onBeforeUnmount, onMounted } from '@vue/runtime-core';
import useClipboard from 'vue-clipboard3';
import moment from "moment";
import 'moment-duration-format';
import CopyIcon from '@/assets/icons/CopyIcon.vue';
import ClockIcon from '@/assets/icons/ClockIcon.vue';

export default {
  name: 'CardHeader',
  components: {
    CopyIcon,
    ClockIcon
  },
  props: {
    id: {
      type: String,
      required: true
    },
    avgMinutes: {
      type: Number,
      default: 0,
      required: true,
    },
    shopCategory: {
      type: String,
      default: '',
      required: true,
    },      
    pendingAt: {
      type: String,
      required: true,
    },
    madeAt: {
      type: [String, null],
      required: true,
    },     
    homePage: {
      type: Boolean,
      default: true,
    }   
  },
  emits: {
    changeTimer: null,
  },
  setup(props, {emit}){
    let timer = ref(null);
    let actualTime = ref(null);
    let timerStatus = ref(null);

    const getActualTime = () => {
      const today = moment().local();
      const timeLocal = moment(props.pendingAt).local();
      const orderDoneTime = moment(props.madeAt).local();
      let duration = null;

      if(props.shopCategory === 'Gastronomía') {
        if(props.homePage) {
           duration = moment.duration(today.diff(timeLocal));
           const halfTime = props.avgMinutes / 2;
           const minutes = duration.asMinutes();

          if (minutes < halfTime) {
            timerStatus.value = 'blue';
            emit('changeTimer', timerStatus);
          } else if (minutes >= halfTime && minutes <= props.avgMinutes) {
            timerStatus.value = 'yellow';
            emit('changeTimer', timerStatus);
          } else {
            timerStatus.value = 'red';
            emit('changeTimer', timerStatus);
          }
        } else {       
          duration = moment.duration((orderDoneTime).diff(timeLocal));    
        }

         if(moment(duration).diff(timeLocal, 'hours') > 24) {
            actualTime.value = orderDoneTime.isValid()
            ? orderDoneTime.format('DD/MM/YYYY')
            : timeLocal.format('DD/MM/YYYY');
          } else {
            actualTime.value = duration.format('mm:ss');
          }

      } else {
        if(props.homePage) {
          timerStatus.value = 'blue';
          emit('changeTimer', timerStatus);
        } 

        actualTime.value = timeLocal.format('DD/MM/YYYY HH:mm');
      }
    }

    onMounted(() => {
      if (props.homePage && props.shopCategory === 'Gastronomía') {      
        timer = setInterval(() => {
          getActualTime();
        }, 200);
      } else {
        getActualTime();
      }
    });

    onBeforeUnmount(() => {
      if(props.homePage){
        clearInterval(timer);
      }
    })

    return { actualTime, timerStatus }
  },  
  methods: {
    shortenId(id) {
      return `...${id.substring(id.length - 4, id.length)}`
    },
    async copyId() {
      const { toClipboard } = useClipboard();

      try {
        await toClipboard(this.id)
      } catch (e) {
        console.error(e)
      }    
    },   
  }   
}
</script>

<template>
  <div class="card__header">
    <div class="card__id">
      {{ `ID: ${shortenId(id)}` }}
      <CopyIcon class="card__icon-copy" @click="copyId" />
    </div>
    <div class="card__timer" :class="homePage && `card__timer-${timerStatus}`">
      <ClockIcon
        class="card__icon"
        :class="homePage && `card__icon-${timerStatus}`"
      />
      {{ `${actualTime}` }}
    </div>
  </div>
</template>

<style lang="scss">
@import "~styles/components/card.scss";
</style>