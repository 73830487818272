import { onError } from '@apollo/client/link/error';
import { ApolloClient, createHttpLink, InMemoryCache, split } from '@apollo/client/core';
import { createApolloProvider } from '@vue/apollo-option';
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from "@apollo/client/utilities";
import { setContext } from "@apollo/client/link/context";
import { provideApolloClient } from '@vue/apollo-composable';



// HTTP connection to the API
const httpLink = createHttpLink({
    uri: process.env.VUE_APP_GRAPHQL_HTTP || 'http://localhost:4040/api/v1/graphql',
});

const errorLink = onError(({ operation, graphQLErrors, networkError }) => {
    const { response } = operation.getContext();
    if (response && response.status === 401) {
        if (typeof localStorage !== 'undefined') {
            localStorage.removeItem('AUTH_TOKEN');
        }
        window.location.href = `${process.env.VUE_APP_LOGIN_BASE_URL}/logout`;
    }

    if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.log(graphQLErrors);
        // eslint-disable-next-line no-console
        console.log(networkError);
    }
});

const authLink = setContext(async (_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem('AUTH_TOKEN');
    // Return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            Authorization: `Bearer ${token}`
        }
    }
});

const wsLink = new WebSocketLink({
    uri: process.env.VUE_APP_GRAPHQL_WS || 'ws://localhost:4040/subscriptions',
    options: {
        reconnect: true,
        connectionParams: () => {
            const token = localStorage.getItem('AUTH_TOKEN');
            return { token };
        },
    },
});

const link = split(
    // split based on operation type
    ({ query }) => {
        const definition = getMainDefinition(query);
        return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    wsLink,
    errorLink.concat(authLink.concat(httpLink)),
);


// Cache implementation
const cache = new InMemoryCache()

// Create the apollo client
const apolloClient = new ApolloClient({
    link,
    cache,
    connectToDevTools: true,
})

provideApolloClient(apolloClient);

// Manually call this when user logs out
export async function onLogout(apolloClient) {
    try {
        await apolloClient.clearStore();
    } catch (e) {
        console.log(e);
    } finally {
        if (typeof localStorage !== 'undefined') {
            localStorage.removeItem('AUTH_TOKEN');
        }
    }
}

export const apolloProvider = createApolloProvider({
    defaultClient: apolloClient,
})