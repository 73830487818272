<template>
  <v-select
    v-if="assignedCampaigns.length > 1"
    v-model="activeCampaign"
    :options="assignedCampaigns"
    :reduce="(campaign) => campaign.id"
    label="name"
    :clearable="false"
    class="custom-filter"
    transition="fade"
  />
</template>

<script>
import { computed } from 'vue';
import gql from 'graphql-tag';
import { useRoute } from 'vue-router';
import router from '@/router';
import assignedCampaignsQuery from '../graphql/queries/assignedCampaigns.gql';
import 'vue-select/dist/vue-select.css';

export default {
  name: 'ButtonFilter',
  props: {
    campaignId: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      assignedCampaigns: [],
    };
  },
  setup(props, { emit }) {
    const route = useRoute();

    const activeCampaign = computed({
      get() {
        return props.campaignId;
      },
      set(newValue) {
        emit('update:campaign', newValue);
      },
    });  

    return { route, activeCampaign };
  }, 
  apollo: {
    assignedCampaigns: {
      query: gql`${assignedCampaignsQuery}`,
      result({ data }){
        const { assignedCampaigns: campaigns } = data;

        if(campaigns.length === 0){
          router.replace({ name: 'no-campaigns' });
        }else if (!this.activeCampaign) {
          this.activeCampaign = campaigns[0].id;
        }
      }
    },
  },
}
</script>

<style scoped lang="scss">
@import "~styles/components/button-filter.scss";
</style>
