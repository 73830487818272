<script>
import Button from './ButtonComponent.vue';
import alert from '@/assets/icons/alert.svg';

export default {
  components: { Button },
  name: 'ModalComponent',
  props: {
    action: {
      type: String,
      default: '',
    },
    content: {
      type: String,
      required: true,
    },
    showModal: {
      type: Boolean,
      default: false,
    },
    onAccept: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      alert,
    }
  },  
  setup(_, { emit }) {   
    function hideModal(){
      emit('hide-modal');
    }

    return { hideModal }
  },
};
</script>

<template>
  <div
    class="modal-container"
    :class="showModal && 'visible'"
    @click="hideModal"
  >
    <div class="modal">
      <div class="modal__title">
        <img :src="alert" alt="alert" class="modal__icon" />{{
          $t("confirm", { action })
        }}
      </div>
      <div class="modal__content">{{ content }}</div>
      <div class="modal__footer">
        <Button color="muted" variant="outline" size="sm" @click="hideModal">{{
          $t("cancel")
        }}</Button>
        <Button size="sm" @click="onAccept">{{ $t("accept") }}</Button>
      </div>
    </div>
  </div>
</template>


<style scoped lang="scss">
@import "~styles/components/modal.scss";
</style>
