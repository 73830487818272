import { createApp } from 'vue';
import { apolloProvider } from './vue-apollo';
import App from './App.vue';
import router from "./router";
import i18n from './i18n';
import axios from 'axios';
import VueAxios from 'vue-axios';
import vSelect from 'vue-select';

const app = createApp(App)
    .use(router)
    .use(i18n)
    .use(apolloProvider)
    .use(VueAxios, axios)
    .provide('axios')

app.component('vSelect', vSelect)
app.mount('#app')
