<script>
import gql from 'graphql-tag';
import Card from '../components/Card/index.vue';
import getOrdersGql from '../graphql/queries/getOrders.gql';
import shopByCampaignId from '../graphql/queries/getShopByCampaignId.gql';
import { VueScreenSizeMixin } from 'vue-screen-size';
import { useRoute } from 'vue-router';
import NoOrders from '@/components/NoOrders.vue';
import { ref, watch } from 'vue';
import { HalfCircleSpinner } from 'epic-spinners';
import { useQuery } from '@vue/apollo-composable';

export default {
  components: { Card, NoOrders, HalfCircleSpinner },
  mixins: [VueScreenSizeMixin],
  setup(){
    const route = useRoute();   
    const orders = ref([]);
    const shopCategory = ref('');
    const scrollComponent = ref(null);
    const allOrders = ref(false);
    const variables = ref({
          campaign: route.params.id,
          ordering: "DESC",
          keysStatesCarts: ["2", "3", "4"],
          page: 1,
          length: 10,
    });  
    
    const { loading, onResult } = useQuery(gql`${getOrdersGql}`, 
      variables, {
          fetchPolicy: 'no-cache',
          enabled: route.params.id && !allOrders.value }, 
      );

      onResult((queryResult) => {
        if (queryResult.data) {
          if(queryResult.data.getOrders.length > 0) {
            orders.value = [...orders.value, ...queryResult.data.getOrders];
           
          } else {
            allOrders.value = true;
          }
        } 
      })

      watch(() => route.params.id, id => {
        variables.value = {
          ...variables.value,
          campaign: id,
          page: 1
        }
    })

    const handleScroll = () => {
      let element = scrollComponent.value;
      
      if(element?.scrollHeight - element?.scrollTop === element?.clientHeight){
        if (!loading.value && !allOrders.value) {
          variables.value = {
            ...variables.value,
            page: variables.value.page + 1
          }
        }
      }
    }

    return { 
      route, 
      shopCategory, 
      orders, 
      loading,
      allOrders,
      handleScroll, 
      scrollComponent }
  },
  data() {
    return {
      enableChangeView: false,
    }
  },
  mounted() {
    if(this.$vssHeight >= 800) {
      this.enableChangeView = true;      
    }
    
    window.addEventListener("scroll", this.handleScroll);
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  }, 
  apollo: {
    getShopByCampaignId:{
      query: gql`${shopByCampaignId}`,
      skip() {
        return !this.route.params.id;
      },
      variables() { 
        return {
          id: this.route.params.id,
        };       
      },
      result({data}) {
        const { getShopByCampaignId: shopData } = data;
        this.shopCategory = shopData.category;
      },
    },  
  }
}
</script>

<template>
<div>
  <div v-if="orders.length > 0" class="home-container">
    <div
      ref="scrollComponent"
      class="home"
      :class="enableChangeView && 'home-full'"
    >
      <Card
        v-for="order in orders"
        :key="order.id"
        :order="order"
        :shopCategory="shopCategory"
        :campaign-id="route.params.id"
        :home-page="false"
      />
    </div>
    <half-circle-spinner
      v-if="loading && !allOrders"
      :animation-duration="1000"
      :size="40"
      :color="'#2255ff'"
      class="home-container__spinner"
    />
  </div>
  <NoOrders v-else />
</div>
</template>

<style lang="scss">
@import "~styles/views/home.scss";
</style>

