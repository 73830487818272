<script>
import Body from './layouts/PageBody.vue';
import Header from './layouts/PageHeader.vue';
import Modal from '@/components/ModalComponent.vue';
import { useI18n } from 'vue-i18n';
import { ref, computed } from 'vue';
import { apolloProvider, onLogout } from './vue-apollo';

export default {
  name: 'App',
  components: { 
    Header, Body, Modal
  }, 
  setup() {
    let showModal = ref(false);
    const { t } = useI18n();

    const action = computed(() => t("logoutAction"));
    const content = computed(() => t("logoutConfirm"));

    return { showModal, action, content }
  },
  methods: {
    showLogoutModal(){
      this.showModal = true;
    },
    hideModal(){
      this.showModal = false;
    },
    logout(){
      onLogout(apolloProvider.defaultClient);
      window.location.href = `${process.env.VUE_APP_LOGIN_BASE_URL}/logout`;
    }
  }
}
</script>

<template>
  <Header @show-logout-modal="showLogoutModal" />
  <Body>
    <router-view v-slot="{ Component, route }">
      <transition name="fade" mode="out-in">
        <div>
          <Modal
            :action="action"
            :content="content"
            :show-modal="showModal"
            :on-accept="logout"
            @hide-modal="hideModal"
          />
          <component :is="Component" :key="route.path" />
        </div>
      </transition>
    </router-view>
  </Body>
</template>


<style lang="scss">
@import "~styles/app.scss";
</style>
