<script>
import { ref } from 'vue';

export default {
  name: 'ButtonComponent',
  props: {
    type: {
      type: String,
      default: 'button',
    },
    variant: {
      type: String,
      default: 'fill',
    },
    color: {
      type: String,
      default: 'secondary',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'md',
    },
  },
  emits: {
    click: null,
  },
  setup() {
    const button = ref(null);

    return {     
      button,
    };
  },
  data() {
    return {
      ripples: [],
    };
  },
  computed: {
    activeRipples() {
      return this.ripples.filter(r => r.show);
    },
  },
  methods: {
    animateRipple(e) {
      const el = this.$refs.button;
      const pos = el.getBoundingClientRect();
      this.ripples.push({
        x: e.clientX - pos.left,
        y: e.clientY - pos.top,
        show: true,
      });

      this.$emit('click', e);
    },
    rippleEnd(i) {
      this.ripples[i].show = false;
    },
  },
};
</script>

<template>
  <button
    ref="button"
    class="button"
    :class="[variant, color, size]"
    :disabled="disabled"
    @click="animateRipple"
  >
    <slot />
    <transition-group>
      <span
        v-for="(val, i) in activeRipples"
        :ref="'button-effect' + i"
        :key="'button-effect' + i"
        class="button-effect"
        :style="{ top: val.y + 'px', left: val.x + 'px' }"
        @animationend="rippleEnd(i)"
      />
    </transition-group>
  </button>
</template>


<style scoped lang="scss">
@import "~styles/components/button.scss";
</style>
