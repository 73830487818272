import { createRouter, createWebHistory } from "vue-router";
import HomePage from "../views/HomePage.vue";
import PastOrders from "../views/PastOrders.vue";
import NoCampaigns from "../views/NoCampaigns.vue";

const routes = [
    { path: "/:id?/", component: HomePage, alias: ['/:id?/home'], name: 'home' },
    { path: "/:id/past-orders", component: PastOrders, name: 'past-orders' },
    { path: "/campaigns", component: NoCampaigns, name: 'no-campaigns' },
    { path: '/:pathMatch(.*)*', redirect: { name: 'home' } },
];

const history = createWebHistory();

const router = createRouter({
    history,
    routes,
});


export default router;