<script>
import gql from 'graphql-tag';
import Card from '../components/Card/index.vue';
import getOrdersGql from '../graphql/queries/getOrders.gql';
import shopByCampaignId from '../graphql/queries/getShopByCampaignId.gql';
import addOrderGql from '../graphql/subscriptions/addOrder.gql';
import { VueScreenSizeMixin } from 'vue-screen-size';
import { useRoute } from 'vue-router';
import NoOrders from '@/components/NoOrders.vue';
import { ref, watch } from 'vue';
import { HalfCircleSpinner } from 'epic-spinners';
import { useQuery } from '@vue/apollo-composable';
import bubbleSoundUrl from '@/assets/sounds/bubble-notification.mp3';
import beepSoundUrl from '@/assets/sounds/beep.mp3';
import notificationIcon from '@/assets/images/notification-icon.png';
import notificationBadge from '@/assets/images/notification-badge.png';
import { Howl } from 'howler';
import {
  APP_PLAY_SOUND,
  APP_SHOW_NOTIFICATION,
} from '@/eventTypes';
import EventBus from '@/eventBus';

export default {
  components: { Card, NoOrders, HalfCircleSpinner },
  mixins: [VueScreenSizeMixin],
  setup() {
    const route = useRoute();
    const orders = ref([]);
    const avgMinutes = ref(0);
    const shopCategory = ref('');
    const scrollComponent = ref(null);
    const allOrders = ref(false);
    const variables = ref({
          campaign: route.params.id,
          ordering: "ASC",
          keysStatesCarts: ["1", "6"],
          page: 1,
          length: 10,
    });

    const markOrderAsDone = (orderId) => {
      orders.value = orders.value.filter((order) => order.id !== orderId);
    }
    const { loading, onResult } = useQuery(gql`${getOrdersGql}`, 
      variables, {
          fetchPolicy: 'no-cache',
          enabled: route.params.id && !allOrders.value }, 
      );

      onResult((queryResult) => {
        if (queryResult.data) {
          if(queryResult.data.getOrders.length > 0) {
            orders.value = [...orders.value, ...queryResult.data.getOrders];
          } else {
            allOrders.value = true;
          }
        } 
      })

      watch(() => route.params.id, id => {
        variables.value = {
          ...variables.value,
          campaign: id,
          page: 1
        }
    })

    const handleScroll = () => {
      let element = scrollComponent.value;

      // if (element?.getBoundingClientRect().bottom < (window.innerHeight/2)) {
      if(element?.scrollHeight - element?.scrollTop === element?.clientHeight){
        if (!loading.value && !allOrders.value) {
          variables.value = {
            ...variables.value,
            page: variables.value.page + 1
          }
        }
      }
    }

    return {
      route,
      orders,
      markOrderAsDone,
      handleScroll,
      loading,
      allOrders,
      scrollComponent,
      avgMinutes,
      shopCategory,
    };
  },
  data() {
    return {
      enableChangeView: false,
      notificationSound: null,
      permissionStatus: '',
    };
  },
  mounted() {
    this.notificationSoundBeep = new Howl({
      html5: true,
      src: [beepSoundUrl],
      format: ['mp3'],
    });

    this.notificationSoundBubble = new Howl({
      html5: true,
      src: [bubbleSoundUrl],
      format: ['mp3'],
    });
    if (this.$vssHeight >= 800) {
      this.enableChangeView = true;      
    }
    this.askNotificationPermission(),

    EventBus.on(APP_SHOW_NOTIFICATION, (title, body) => {
      this.showNotification(title, body);
    }),

    EventBus.on(APP_PLAY_SOUND, () => {
      this.playNotification();
    })

    window.addEventListener("scroll", this.handleScroll);
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);

    EventBus.off(APP_SHOW_NOTIFICATION),
    EventBus.off(APP_PLAY_SOUND)
  },

  methods: {
    askNotificationPermission() {
      try {
        Notification.requestPermission().then(
          (permission) => {
            this.permissionStatus = permission;
          },
        );
      } catch (e) {
        Notification.requestPermission(
          (permission) => {
            this.permissionStatus = permission;
          },
        );
      }
    },
    showNotification(title) {
        const mainTitle = this.$t("newOrder");
        const mainBody = this.$t("number", { order: title.newOrder.order });

        (new Notification(mainTitle, {
          body: mainBody,
          icon: notificationIcon,
          badge: notificationBadge,
          tag: 'appNotificationStack',
          requireInteraction: false,
          renotify: true,
        })).onclick = function doFocus(e) {
          e.preventDefault();
          window.focus();
          this.close();
        };
    },
  },

  apollo: {
    getShopByCampaignId:{
      query: gql`${shopByCampaignId}`,
      skip() {
        return !this.route.params.id;
      },
      variables() { 
        return {
          id: this.route.params.id,
        };       
      },
      result({data}) {
        const { getShopByCampaignId: shopData } = data;
        this.avgMinutes = shopData.settings.avgMinutes;
        this.shopCategory = shopData.category;
      },
    },
    $subscribe: {
      addOrder: {
        query: addOrderGql,
        skip() {
          return !this.route.params.id;
        },
        variables() {
          return {
            campaignId: this.route.params.id,
          }
        },
        result({ data }) {
          EventBus.emit(APP_SHOW_NOTIFICATION, data);
          const { newOrder: order } = data;
          const indexOrder = this.orders.findIndex(({ id }) => id === order.id);

          if (indexOrder !== -1) {
            const params = [indexOrder, 1];
            if (order.statecart.key === 1 || order.statecart.key === 6) {
              params.push(order);
            }
            this.orders.splice(...params);
          } else if (order.statecart.key === 1 || order.statecart.key === 6) {
            this.orders.push(order);
          }

          if (order.statecart.key === 1) {
            this.notificationSoundBeep.play();
          } else {
            this.notificationSoundBubble.play();
          }
        },
        error(err) {
          console.log(err);
        },
      },
    }

  }
}
</script>

<template>
<div>
  <div v-if="orders.length > 0" class="home-container">
    <div
      ref="scrollComponent"
      class="home"
      :class="enableChangeView && 'home-full'"
    >
      <Card
        v-for="order in orders"
        :key="order.id"
        :order="order"
        :avgMinutes="avgMinutes"
        :shopCategory="shopCategory"
        :campaign-id="route.params.id"
        home-page
        @mark-as-done="markOrderAsDone"
      />   
    </div>
    <half-circle-spinner
      v-if="loading && !allOrders"
      :animation-duration="1000"
      :size="40"
      :color="'#2255ff'"
      class="home-container__spinner"
    />
  </div>
  <NoOrders v-else />
</div>
</template>

<style>
@import "~styles/views/_home.scss";
</style>

