<script>
import gql from 'graphql-tag';
import changeSaleStatusGql from '../../graphql/mutations/changeSaleStatus.gql';
import { useMutation } from '@vue/apollo-composable';
import { store } from '@/store/index';
import CheckIcon from '@/assets/icons/CheckIcon.vue';
import PrepareIcon from '@/assets/icons/PrepareIcon.vue';
import CardHeader from './CardHeader.vue';
import CardInfo from './CardInfo.vue';
import { ref } from 'vue-demi';

export default {
  name: 'CardComponent',
  components: { CheckIcon, CardHeader, CardInfo, PrepareIcon },
  props: {
    order: {
      type: [Object, null],
      required: true,
    },
    avgMinutes: {
      type: Number,
      default: 0,
    },
    shopCategory: {
      type: String,
      default: '',
      required: true,
    },  
    homePage: {
      type: Boolean,
      default: true,
    }
  }, 
  setup(props, { emit }) {
    let statusKey = ref(null);
    const { mutate: changeSaleStatus } = useMutation(gql`${changeSaleStatusGql}`, 
      () => ({ variables: { id: props.order.id, status: statusKey.value } }),
      { error(err) { console.log(err); }
    });

    function changeStatus() {
      statusKey.value = statusKey.value === 6 ? 1 : 6;
      changeSaleStatus();
      const eventName = statusKey.value === 6 ? 'mark-as-preparing' : 'mark-as-done';
      emit(eventName, props.order.id);
    }

    return { statusKey, changeSaleStatus, changeStatus }
  },
  data() {
    return {
      store,
      timerStatus: null,
    }
  },
  computed: {
    isBeingPrepared() {
      return this.order.statecart.key === 6;
    },
    statusBadgeClass() {
      if (this.statusKey === 2) {
        return 'card__badge-done';
      }
      if (this.statusKey === 3) {
        return 'card__badge-shipping';
      }

      return 'card__badge-delivered';
    },
    statusClass() {
      return `card__status-${this.timerStatus}`;
    },
    statusColorClass() {
      return this.order.statecart.key === 1 ? 'card__button--red' : 'card__button--green';
    },  
  },
  methods: {
    getButtonIcon() {
      return this.isBeingPrepared ? 'CheckIcon' : 'PrepareIcon';
    },
    getButtonText() {
      return this.isBeingPrepared ? this.$t('done') : this.$t('being-prepared');
    },
    changeTimer(status) {
      this.timerStatus = status;
    },
    getStatus() {
      this.statusKey = parseInt(this.order.statecart.key)   
      let status = '';   

      if (this.statusKey === 2) {
        status = this.$t('statusDone');
      } else if(this.statusKey === 3) {
        status = this.$t('statusShipping');
      } else {
        status = this.$t('statusDelivered');
      }

      return status;
    },
  }
}
</script>

<template>
  <transition>
    <div ref="card" class="card">
      <div class="card__status" :class="homePage && statusClass" />
      <div
        class="card__body"
        :class="{ 'card__body-small': store.view === 'small' }"
      >
        <CardHeader
          :id="order?.id"
          :avgMinutes="avgMinutes"
          :shopCategory="shopCategory"
          :pending-at="order.pendingAt"
          :made-at="order.madeAt"
          :home-page="homePage"
          @change-timer="changeTimer"
        />
        <CardInfo :order-number="order.order" :cart="order" />
        <div
          v-if="homePage"
          class="card__button"
          :class="statusColorClass"
          @click="
            changeStatus();
          "
        >
        <CheckIcon v-if="getButtonIcon() === 'CheckIcon'" class="card__icon-done" />
        <PrepareIcon v-else class="card__icon-prepare" />
        {{ getButtonText() }}
        </div>
        <div v-else :class="statusBadgeClass">{{ getStatus() }}</div>
      </div>
    </div>
  </transition>
</template>

<style lang="scss">
@import "~styles/components/card.scss";
</style>